import Axios from 'axios';
// import { mask } from 'vue-the-mask';
import specialties_prices from '../../data/specialties_prices.json';
import Vue from 'vue';
import TextareaAutosize from 'vue-textarea-autosize';

Vue.use(TextareaAutosize);

export default {

  data() {
    return {
      tabs: [
        {
          id: 'creator',
          name: "Digital Influencers",
          text: 'newbriefing.creator',
          image: '/images/brief/creator.png'
        },
        {
          id: 'freelancer',
          name: "Freelancers",
          text: 'newbriefing.freelancer',
          image: '/images/brief/freelancer.png'
        },
      ],
      isShowForm: false,
      selectedTab: null,
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      COMPANYID: this.$store.state.me.company_id,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
      company: {
        address: [],
      },
      search: '',
      searchComplementary: '',
      categories: [],
      complementary: [],
      mainSpecialityInput: '',
      specialitiesInput: '',
      verticalInput: '',
      personalInput: '',
      regionalInput: '',
      experienceInput: '',
      errors: {
        mainspeciality: [],
        specialities: [],
        verticals: [],
        personal: [],
        regional: [],
        experience: [],
      },
      tags: {
        //
      },
      form: {
        tasks: '',
        address: 0,
        main: {},
        specialities: [],
        verticals: [],
        personal: [],
        regional: [],
        experience: [],
        worktype: "remote",
        brand: "",
        audience: "",
        team: "",
        frequency: "",
        value: "",
        visibility: {
          main: true,
          specialities: false,
        },
      },
      date_start: null,
      date_end: null,
      specialities: [],
      collapse: {
        one: true,
        two: false,
        three: false,
        four: false,
        five: false,
        six: false,
        seven: false,
        eight: false,
      },
      attachments: [],
      syncing: false,
      specialty_daily_price: 0,
      days: 0,
      postChannels: [
        {
          id: 'youtube', name: "Youtube", active: false,
        },
        {
          id: 'tik_tok', name: "Tiktok", active: false,
        },
        {
          id: 'instagram', name: "Instagram", active: false,
        },
        {
          id: 'twitter', name: "Twitter", active: false,
        },
        {
          id: 'kwaii', name: "Kwaii", active: false,
        },
        {
          id: 'twitch', name: "Twitch", active: false,
        },
        {
          id: 'facebook', name: "Facebook", active: false,
        },
        {
          id: 'linkedin', name: "LinkedIn", active: false,
        },

        {
          id: 'naosei', name: "Não sei", active: false,
        },
      ],
      sizes: [
        {
          name: 'Nano - 1k a 10k', active: false,
        },
        {
          name: 'Micro - 10k a 50k', active: false,
        },
        {
          name: 'Médio - 50 a 500k', active: false,
        },
        {
          name: 'Macro - 500k a 1M', active: false,
        },
        {
          name: 'Mega - 1M+', active: false,
        },
        {
          name: 'Super - 10M+', active: false,
        },
        {
          name: 'Não sei', active: false,
        },
      ],
      shortcuts: [
        {
          id: 'trips', name: 'Viagens', selected: false,
        },
        {
          id: 'diy', name: 'DIY', selected: false,
        },
        {
          id: 'politics', name: 'Política', selected: false,
        },
        {
          id: 'woman', name: 'Mulher', selected: false,
        },
        {
          id: 'non-binary-person', name: 'Pessoa não binária', selected: false,
        },
        {
          id: 'trip', name: '', selected: false,
        },
        {
          id: 'lifestyle', name: 'Lifestyle', selected: true,
        }
      ]
    }
  },

  mounted() {

    let $ = window.jQuery;

    $('[data-toggle="tooltip"]').tooltip();

    $(document).on("input", "#texto-projeto", function () {
      var limite = 1200;
      var caracteresDigitados = $(this).val().length;
      var caracteresRestantes = limite - caracteresDigitados;

      if (caracteresRestantes <= 0) {
        var comentario = $("textarea[name=texto-projeto]").val();
        $("textarea[name=texto-projeto]").val(comentario.substr(0, limite));
        $(".caracteres").text("(0)");
      } else {
        $(".caracteres").text("- (" + caracteresRestantes + ")");
      }
      if ($("textarea[name=texto-projeto]").val() == "") {
        $(".caracteres").text("");
      }

    });

    // jQuery('#texto-projeto').bind('copy paste', function (e) {
    //    e.preventDefault();
    // });

    window.feather.replace();

    if (this.$store.state.role == 'admin') this.$router.push({ path: '/dashboard/admin' });
    if (this.$store.state.role == 'professional') this.$router.push({ path: '/dashboard/professional' });
    if (this.$store.state.role == '') this.$router.push({ path: '/' });

    Axios.get('/api/v1/categories').then(response => {
      for (let i = 0; i < response.data.data.length; i++) {
        let category = response.data.data[i];
        category.show = false;
        for (let j = 0; j < category.specialities.length; j++) {
          let speciality = category.specialities[j];
          speciality.selected = false;
        }
      }
      this.categories = response.data.data;
    });


    Axios
      .get('/api/v1/specialities', {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          this.specialities = response.data.data;
        } else {
          console.log(response.data.error);
        }
      });

    Axios
      .get('/api/v1/companies/' + this.COMPANYID, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          this.company = response.data.data;

          if (this.company.currency == 'eur') {
            this.money.prefix = '€ ';
          }
          if (this.company.currency == 'usd') {
            this.money.prefix = '$ ';
          }

        } else {
          console.log(response.data.error);
        }
      });

  },

  computed: {

    filteredCategories() {
      if (this.search && this.search != "") {
        return this.categories.map(category => {
          category.filteredSpecialities = category.specialities
          .filter(speciality => {
            return speciality.pt.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
          })
          return category;
        });

      } else {
        for (var i = 0; i < this.categories.length; i++) {
          this.categories[i].filteredSpecialities = this.categories[i].specialities;
        }
        return this.categories;
      }
    },

    filteredComplementary() {
      if (this.searchComplementary && this.searchComplementary != "") {
        return this.categories.map(category => {
          category.filteredSpecialitiesComplementary = category.specialities
          .filter(speciality => {
            return speciality.pt.toLowerCase().indexOf(this.searchComplementary.toLowerCase()) > -1;
          })
          .filter(specialty => specialty.id != 298);
          return category;
        });

      } else {
        for (var i = 0; i < this.categories.length; i++) {
          this.categories[i].filteredSpecialitiesComplementary = this.categories[i].specialities.filter(specialty => specialty.id != 298);
        }
        return this.categories;
      }
    },

  },

  watch: {

    form() {
      this.specialty_daily_price = specialties_prices.find(specialty => specialty.pt === this.form.main.pt).daily_price;
      if (this.days > 0)
        this.form.value = this.money.prefix + ((this.days + 1) * this.specialty_daily_price) + ",00";
    },

    date_start() {

      let start = this.date_start ? new Date(this.date_start) : Date.now();
      let end = this.date_end ? new Date(this.date_end) : Date.now();
      let difference = end.getTime() - start.getTime();
      this.days = difference / (1000 * 3600 * 24);

      if (this.specialty_daily_price > 0)
        this.form.value = this.money.prefix + ((this.days + 1) * this.specialty_daily_price) + ",00";
    },

    date_end() {

      let start = this.date_start ? new Date(this.date_start) : Date.now();
      let end = this.date_end ? new Date(this.date_end) : Date.now();
      let difference = end.getTime() - start.getTime();
      this.days = difference / (1000 * 3600 * 24);

      if (this.specialty_daily_price > 0)
        this.form.value = this.money.prefix + ((this.days + 1) * this.specialty_daily_price) + ",00";
    },
  },

  methods: {

    chooseSpeciality(speciality) {
      let double = this.form.specialities.map(function (e) { return e.pt; }).indexOf(speciality.pt);
      if (double < 0 && this.form.main.id != speciality.id) {
        this.form.main = speciality;
      }
    },

    chooseSpecialityComplementary(speciality) {
      let double = this.form.specialities.map(function (e) { return e.pt; }).indexOf(speciality.pt);
      // não está ainda na lista selecionada de habilidades
      if (double < 0 && this.form.main.id != speciality.id) {
        if (this.form.specialities.length < 3) this.form.specialities.push(speciality);
      } else {
        this.removeSpeciality(speciality);
      }
    },

    handleSelectTab(id) {
      this.selectedTab = id;
      this.isShowForm = true;
      if (id == 'freelancer') {
        this.form.tasks = '[Empresa] procura [cargo/função/talento] para [entrega principal/missão] durante [dias, semanas, meses]. Procuramos um talento com [pelo menos X anos de experiência] para nos ajudar com as entregas de [peças, conceito, site, produto, pesquisa, produção etc]. A disponibilidade esperada é [durante o horário comercial / parcial / a combinar]. Priorizamos pessoas com as seguintes características [etnia, gênero] residentes em [cidade/estado/país].';
      }
    },

    validateVertical(e) {
      if (this.form.verticals.length < 4) {
        let text = this.verticalInput;
        let index = this.specialities.map(function (e) { return e.pt; }).indexOf(text);
        let double = this.form.verticals.indexOf(text);
        // faz parte da lista de habilidades
        if (index > -1) {
          // não está ainda na lista selecionada de habilidades
          if (double < 0) this.form.verticals.push(text);
        }
      } else {
        this.errors.verticals.push("Limite de 4 tags.");
      }
      this.verticalInput = '';
    },

    validatePersonal(e) {
      if (this.form.personal.length < 4) {
        let text = this.personalInput;
        let index = this.specialities.map(function (e) { return e.pt; }).indexOf(text);
        let double = this.form.personal.indexOf(text);
        // faz parte da lista de habilidades
        if (index > -1) {
          // não está ainda na lista selecionada de habilidades
          if (double < 0) this.form.personal.push(text);
        }
      } else {
        this.errors.personal.push("Limite de 4 tags.");
      }
      this.personalInput = '';
    },

    validateExperience(e) {
      if (this.form.verticals.length < 3) {
        let text = this.experienceInput;
        let index = this.specialities.map(function (e) { return e.pt; }).indexOf(text);
        let double = this.form.experience.indexOf(text);
        // faz parte da lista de habilidades
        if (index > -1) {
          // não está ainda na lista selecionada de habilidades
          if (double < 0) this.form.experience.push(text);
        }
      }
      this.experienceInput = '';
    },

    removeMain(s) {
      // remove habilidade main do briefing
      this.form.main = {};
      this.form.visibility.main = true;
      this.form.visibility.specialities = false;
    },

    removeSpeciality(s) {
      // remove habilidade do briefing
      let index = this.form.specialities.map(function (e) { return e.pt; }).indexOf(s.pt);
      if (index > -1) {
        this.form.specialities.splice(index, 1);
        this.form.visibility.specialities = true;
      }
    },

    removeVerticals(s) {
      // remove habilidade do briefing
      let index = this.form.verticals.indexOf(s);
      if (index > -1) {
        this.form.verticals.splice(index, 1);
        this.errors.verticals = [];
      }
    },

    removePersonal(s) {
      // remove habilidade do briefing
      let index = this.form.personal.indexOf(s);
      if (index > -1) {
        this.form.personal.splice(index, 1);
        this.errors.personal = [];
      }
    },

    removeExperience(s) {
      // remove habilidade do briefing
      let index = this.form.experience.indexOf(s);
      if (index > -1) {
        this.form.experience.splice(index, 1);
      }
    },

    handleAttachmentUpload(ev) {
      this.attachments.push(this.$refs.attachments.files[0]);
      ev.target.value = '';
    },

    removeAttachment(a) {
      let index = this.attachments.map((e) => { return e.name }).indexOf(a.name);
      if (index > -1) {
        this.attachments.splice(index, 1);
      }
    },

    submit(e) {

      let $ = window.jQuery;

      // check if user has basic info
      if (this.company.razao == '' ||
        this.company.cnpj == '' ||
        this.company.phone == '' ||
        this.company.address.length < 1 ||
        (!this.company.address[0].city_id && !this.company.address[0].city_open) ||
        this.me.name == '') {
        $('#complete-info-modal').modal('show');
        return;
      }
      let _this = this;
      let fail = false;
      let form = _this.form;
      let formData = new FormData();

      _this.syncing = true;

      // main speciality
      if (!_this.form.main.id && this.selectedTab == "freelancer") {
        _this.syncing = false;
        _this.errors.mainspeciality.push("Você precisa selecionar uma habilidade principal.");
        fail = true;
      }

      if (this.selectedTab == "creator") {
        // tag princial é content creator
        formData.append("specialities[98]", 1);
        // canais para postar
        for (let i = 0; i < this.postChannels.length; i++) {
          if (this.postChannels[i].active) formData.set("channels["+i+"]", this.postChannels[i].name);
        }
        for (let i = 0; i < this.sizes.length; i++) {
          if (this.sizes[i].active) formData.set("size["+i+"]", this.sizes[i].name);
        }
      }

      if (_this.form.main.id) formData.append("specialities[" + _this.form.main.id + "]", 1);

      // somar as tags specialities, verticals, personal
      if (form.specialities.length + form.verticals.length + form.personal.length < 2) {
        // _this.syncing = false;
        // _this.errors.specialities.push("Escolha no mínimo 2 habilidades.");
        // fail = true;
        //
        // // open
        // $('#h3-1').attr("aria-expanded", "true");
        // $('#h3-1').removeClass('active collapsed');
        // $('#collapseOne').addClass('show');
        // $('#h3-2, #h3-3').attr("aria-expanded", "false");
        // $('#h3-2, #h3-3').addClass('active collapsed');
        // $('#collapseTwo, #collapseThree').removeClass('show');
        // $('#h3-1').click(function () {
        //   if ($(this).attr('aria-expanded') === "true") {
        //     $(this).addClass('active');
        //   } else {
        //     $(this).removeClass('active');
        //   }
        // });
        //
        // $('#h3-2, #h3-3').click(function () {
        //   if ($('#h3-1').attr('aria-expanded') === "true") {
        //     $('#h3-1').addClass('active');
        //   }
        // });

      }
      else _this.errors.specialities = [];

      for (let i = 0; i < form.specialities.length; i++) {
        formData.append("specialities["+_this.form.specialities[i].id+"]", 2);
      }

      let index = null;

      // verticais - 3
      for (let i = 0; i < form.verticals.length; i++) {
        index = _this.specialities.map(function (e) { return e.pt; }).indexOf(form.verticals[i]);
        formData.append("specialities["+_this.specialities[index].id+"]", 3);
      }

      // pessoal - 4
      for (let i = 0; i < form.personal.length; i++) {
        index = _this.specialities.map(function (e) { return e.pt; }).indexOf(form.personal[i]);
        formData.append("specialities["+_this.specialities[index].id+"]", 4);
      }

      // time - 6
      for (let i = 0; i < form.experience.length; i++) {
        index = _this.specialities.map(function (e) { return e.pt; }).indexOf(form.experience[i]);
        formData.append("specialities["+_this.specialities[index].id+"]", 6);
      }

      // after all validation, return if fail
      if (fail) return;

      let value = parseInt(form.value.replace(/\D/g, ''));

      if (this.company.currency == 'eur') {
        value = value * this.$store.state.currencies.eur;
      }
      if (this.company.currency == 'usd') {
        value = value * this.$store.state.currencies.usd;
      }

      formData.set("value", value);
      formData.set("title", form.title);
      formData.set("tasks", form.tasks);

      // content creator
      if (form.brand != "") formData.set("brand", form.brand);
      if (form.audience != "") formData.set("audience", form.audience);
      if (form.team != "") formData.set("team", form.team);
      if (form.frequency != "") formData.set("frequency", form.frequency);



      formData.set("date_start", _this.date_start);
      formData.set("date_end", _this.date_end);
      formData.set("company_computer", form.company_computer);
      formData.set("worktype", form.worktype);

      formData.set("state", _this.company.address[form.address].state);
      formData.set("city", _this.company.address[form.address].city);
      formData.set("cep", _this.company.address[form.address].cep);
      formData.set("street", _this.company.address[form.address].street);
      formData.set("number", _this.company.address[form.address].number);
      formData.set("complement", _this.company.address[form.address].complement);

      if (_this.attachments.length) {
        for (let i = 0; i < _this.attachments.length; i++) {
          formData.append("attachments[]", _this.attachments[i]);
        }
      }

      // send data to api
      Axios
        .post('/api/v1/hirings', formData, {
          headers: {
            Authorization: 'Bearer ' + _this.TOKEN,
            'Content-Type': 'multipart/form-data',
          }
        })
        .then(response => {
          if (response.data.error) {
            _this.errors = response.data.error;
            if (_this.errors.specialities) {
              $('#h3-1').trigger('click');
            }
            else if (_this.errors.date_end || _this.errors.date_start || _this.errors.attachments || _this.errors.tasks || _this.errors.title) {
              $('#h3-2').trigger('click');
            }
            else if (_this.errors.value || _this.errors.company_computer || _this.errors.worktype) {
              $('#h3-3').trigger('click');
            }
            if (response.data.error.plan) $('#plan-exceeded-modal').modal('show');
            _this.syncing = false;
          } else {
            _this.$router.push({ path: '/dashboard/client' })
          }
        });

    },

    toSettings() {
      let $ = window.jQuery;
      $('#complete-info-modal').modal('hide');
      this.$router.push({ path: '/dashboard/client/settings' });
    },

    toPlans() {
      let $ = window.jQuery;
      $('#plan-exceeded-modal').modal('hide');
      this.$router.push({ path: '/dashboard/client/mycommunity-notamember' });
    },

    handleCollapse(index) {
      switch (index) {
        case 1:
          this.collapse.one = !this.collapse.one;
          this.collapse.two = this.collapse.three = false;
          break;
        case 2:
          this.collapse.two = !this.collapse.two;
          this.collapse.one = this.collapse.three = false;
          break;
        case 3:
          this.collapse.three = !this.collapse.three;
          this.collapse.one = this.collapse.two = false;
          break;
        default:
          this.collapse.one = this.collapse.two = this.collapse.three = false;
          break;
      }
    },
  }

}
